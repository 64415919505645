import qs from 'qs';
import axios from 'axios';

const createPoster = async (route, id)=> {

	if(process.env.NODE_ENV  != 'production') return;

	let {shop_id = '', product_id = '', poster_id = ''} = route.params
	if(shop_id && id) {
		try {
			let header = {},
				obj = {
					shopid: shop_id, 
					productid: product_id, 
					moduleid: poster_id,
					posterid: id
				}
			header['Content-Type']= 'application/x-www-form-urlencoded';
			await axios.post('/hm.gif', qs.stringify(obj), { headers: header})
		} catch (err) {
	
		}
	}

}


export default {
	createPoster,
}